<template>
	<div class="menuWrapper">
		<div class="header flexBetween">
			<div :class="['leftlogo flexCenter']">
				<div class="thumbnail flexCenter">
					<img v-if="$store.state.config.leftlogo" :src="$store.state.config.leftlogo" alt="">
				</div>
			</div>
			<div v-if="$store.state.config.sysname" :class="['moudelname', $store.state.leftmenu ? '' : 'hidelogo1']">{{
				$store.state.config.sysname }}</div>
			<div class="menubar flex_1">
				<div v-if="false"
					style="margin-right:300px ; margin-left: 320px; text-align: center; min-width: 300px; overflow: hidden;">
					<div class="bigmenu">
						<div :class="[
				'bmitem bgBlue flexCenter',
				nowbMenu == item.id ? 'bmckd' : '',
			]" v-for="(item, idx) in Menus" :key="idx" @click="goBMenu(item)">
							<i :class="item.icon"></i> {{ item.title }}
							<div class="ckdline">

							</div>
						</div>
					</div>
				</div>


				<el-tabs ref="tabs">
					<el-tab-pane v-for="(item, idx) in Menus" :key="idx" :name="item.id + ''">
						<div slot="label" style="height:100%;">
							<div :class="[
				'bmitem bgBlue flexCenter',
				nowbMenu == item.id ? 'bmckd' : '',
			]" @click="goBMenu(item)">
								<div class="flexStart" style="height:100%;">
									<i v-if="!item.icon0 && !item.icon1" :class="item.icon" style="color: inherit;"></i>
									<img v-if="item.icon1 && nowsMenu == item.id" :src="item.icon1" alt=""
										style="width: 15px;height: 15px;">
									<img v-if="item.icon0 && nowsMenu != item.id" :src="item.icon0" alt=""
										style="width: 15px;height: 15px;">
									<span style="margin-left: 4px;">{{ item.title }}</span>
								</div>
								<div class="ckdline"></div>
							</div>

						</div>
					</el-tab-pane>

				</el-tabs>


			</div>
			<div class="headright flexCenter">

				<el-popover placement="bottom" width="300" trigger="hover"
					popper-class="popper-class-cus flexCloumnSingleCenter">
					<div class="flexCenter" style="width: 260px;height: 260px;">
						<!-- <img style="width:160px;height:160px;" src="../../../public/img/miniQR.png" alt=""> -->
						<div style="position: relative;width: 90%; text-align: center; margin: 0 auto;">
							<el-image :src="miniqrcodeurl ? miniqrcodeurl : './img/ucenter_icon_async.png'" alt=""
								style="position: unset;width: 100%;height: 100%;"></el-image>
							<div
								style="position:absolute; width:45%; height: 45%; min-width: 80px; min-height: 80px; background-color: #fff; left: 28%; top: 26%; border-radius: 50%;">
								<img v-if="applogo" :src="applogo" style="width:100%;height:100%;border-radius: 50%;" />
							</div>
						</div>
					</div>
					<el-button v-if="miniqrcodeurl" @click="downloadqrcode" type="primary" icon="el-icon-download"
						size="small">下载小程序码图片到本地</el-button>

					<div slot="reference" class="miniQRBox">
						<img src="../../../public/img/miniQR.png" alt="">
					</div>
				</el-popover>


				<el-dropdown @command="handleCommand" trigger="click">
					<div class="flexEnd" style="height: 50px;">

						<div class="avatarImg">
							<img :src="avatar" alt="">
						</div>
						<div class="nickname flexStart">
							<div style="margin-right: 6px;">
								您好，{{ userName }}
							</div>
							<i class="el-icon-caret-bottom"></i>

						</div>

					</div>
					<el-dropdown-menu slot="dropdown">
						<!-- <el-dropdown-item command="changeYear" divided>切换年份</el-dropdown-item> -->
						<el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>

		<div class="menuline"></div>



		<div :class="['leftmenu']">
			<div style="width: 160px; margin: 0 auto; height: 100%;">
				<div style="height: 56px;"></div>

				<div class="leftcollapse" style="height:calc( 100% - 56px );overflow: auto;">

					<el-collapse v-model="nowsMenu" accordion @change="handleChange">
						<el-collapse-item :title="lm.title" :name="lm.id" v-for="(lm, idx) in Smenus" :key="idx">
							<div slot="title"><span :class="nowsMenu == lm.id ? 'bmenuckd' : ''"><i
										:class="lm.icon"></i> {{
				lm.title
			}}</span></div>
							<div>
								<div :class="['menuitem flexStartUp', nowsMenu2 == item.id ? 'menuckd' : '']"
									v-for="(item, idx) in lm.children" :key="idx" @click="goPage(item)">
									<i :class="item.icon" style="line-height:1.7692307"></i> {{ item.title }}
									<div class="ckdtail" v-if="nowsMenu2 == item.id">

									</div>
								</div>
							</div>

						</el-collapse-item>

					</el-collapse>
					<div style="height: 106px;"></div>

				</div>


			</div>
		</div>




		<el-dialog title="修改密码" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">
			<el-form :model="pwdForm">
				<el-form-item>
					<el-input placeholder="请输入原密码" v-model="pwdForm.oldPwd" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">原密码</span></template>
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-input type="password" placeholder="请输入新密码" v-model="pwdForm.newPwd1" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">新密码</span></template>
					</el-input>
				</el-form-item>

				<el-form-item>
					<el-input type="password" placeholder="请再次输入新密码" v-model="pwdForm.newPwd2" auto-complete="off">
						<template slot="prepend"><span style="display: inline-block; width: 60px">确认密码</span></template>
					</el-input>
				</el-form-item>

				<div style="height: 20px;text-align: center;padding-top: 30px;padding-bottom: 20px;">
					<el-button type="primary" style="width: 100%" @click="savePwd">更改密码</el-button>
				</div>
			</el-form>
		</el-dialog>

		<el-dialog title="切换年份" :visible.sync="isShowYearModal" :close-on-click-modal="false" :destroy-on-close="true"
			width="500px">

			<div>
				<el-form :model="pwdForm" inline="inline">

					<el-form-item label="年份">

						<el-select v-model="year">
							<el-option :label="y" :value="y" v-for="(y, i) in yearList" :key="i"></el-option>

						</el-select>

					</el-form-item>

				</el-form>



			</div>

			<div style="height: 20px; text-align: center; padding-top: 30px; padding-bottom: 20px;">
				<el-button type="primary" style="width: 100%;" @click="setYear">确认切换</el-button>
			</div>



		</el-dialog>


	</div>
</template>

<script>
import aes from "../../utils/aes.js";

export default {
	data() {
		return {
			leftLogo: "",
			avatar: "",
			Menus: [],
			Smenus: [],
			nowbMenu: 0,
			nowsMenu: "",
			nowsMenu2: "",
			userName: "",
			isShowModal: false,
			year: "",
			yearList: [],
			isShowYearModal: false,
			ltmenuHide: true,
			pwdForm: {
				oldPwd: "",
				newPwd1: "",
				newPwd2: "",
			},
			miniqrcodeurl: '',
			applogo: ''
		};
	},
	mounted() {
		this.getapplogo()

		this.getminiqrcode()

		this.getRoleMenu().then(_ => {
			if (window.location.href.indexOf('showIframe') > -1) {
				console.log()
				let nowbMenu = decodeURIComponent(this.$GetQueryString('nowbMenu'))
				let nowsMenu2 = decodeURIComponent(this.$GetQueryString('nowsMenu2'))
				this.nowbMenu = nowbMenu
				this.nowsMenu2 = nowsMenu2
				for (let item of this.Menus) {

					if (item.path && (this.$route.path.indexOf(item.path) >= 0 || item.path.indexOf(this.$route.path) >= 0)) {
						this.nowsMenu = 0
						this.nowbMenu = item.id
						haspage = true

					} else {
						for (let sitem of item.children) {
							// console.log(sitem.path, this.$route.path)
							if (sitem.path && (this.$route.path.indexOf(sitem.path) >= 0 || sitem.path.indexOf(this.$route.path) >= 0)) {
								this.nowsMenu = 0
								this.nowbMenu = item.id
								this.Smenus = item.children;
								haspage = true

							} else {
								for (let sitem2 of sitem.children) {
									if (sitem2.path && (this.$route.path.indexOf(sitem2.path) >= 0 || sitem2.path.indexOf(this.$route.path) >= 0)) {
										this.nowsMenu = sitem.id
										this.nowbMenu = item.id
										this.nowsMenu2 = sitem2.id;
										this.Smenus = item.children;
										haspage = true

									} else {


									}
								}

							}
						}
					}
				}
				for (let item of this.Menus) {
					if (!nowsMenu2 && item.id == this.nowsMenu2) {
						this.nowsMenu2 = 0
						this.$store.dispatch("showSmenu", 0)
						this.$store.dispatch("smenuCount", 0)
					} else if (nowsMenu2) {
						for (let sitem of item.children) {
							if (sitem.id == this.nowsMenu2) {
								this.Smenus = item.children;
								this.$store.dispatch("showSmenu", 1)
								this.$store.dispatch("smenuCount", this.Smenus.length)
								break
							} else {
								for (let sitem2 of sitem.children) {
									if (sitem2.id == this.nowsMenu2) {
										this.nowsMenu = sitem.id
										this.nowbMenu = item.id
										this.nowsMenu2 = sitem2.id;
										this.Smenus = item.children;

									}
								}
							}
						}
					}
				}
			}
		});

		let yearList = [];
		for (let i = new Date().getFullYear(); i >= 2021; i--) {
			yearList.push(i)
		}
		this.yearList = yearList;
		if (sessionStorage.getItem("year")) {
			this.year = sessionStorage.getItem("year")
		}


	},
	methods: {
		getapplogo() {


			this.$http.post("/api/sch_config", { pmid: window.pmid }).then(res => {

				for (let k of res.data) {
					switch (k.ktype) {
						case 'applogo':
							this.applogo = k.kvalue
							break;
					}
				}
			})
		},

		getminiqrcode() {
			this.$http.post("/api/getMiniQrcode").then(res => {
				this.miniqrcodeurl = res.data.url
			})
		},
		downloadqrcode() {
			window.open(this.miniqrcodeurl)
		},
		getRoleMenu() {
			console.log(4444444, window.location.href.indexOf('showIframe'))

			return new Promise(resolve => {

				this.$http.post("/api/getUserMenu").then(res => {
					this.Menus = res.data
					this.getTeacherInfo(() => {
						console.log("menu", this.$route.path)
						let haspage = false
						if (window.location.href.indexOf('showIframe') < 0) {


							for (let item of this.Menus) {

								if (item.path && (this.$route.path.indexOf(item.path) >= 0 || item.path.indexOf(this.$route.path) >= 0)) {
									this.nowsMenu = 0
									this.nowbMenu = item.id
									haspage = true

								} else {
									for (let sitem of item.children) {
										// console.log(sitem.path, this.$route.path)
										if (sitem.path && (this.$route.path.indexOf(sitem.path) >= 0 || sitem.path.indexOf(this.$route.path) >= 0)) {
											this.nowsMenu = 0
											this.nowbMenu = item.id
											this.Smenus = item.children;
											haspage = true

										} else {
											for (let sitem2 of sitem.children) {
												if (sitem2.path && (this.$route.path.indexOf(sitem2.path) >= 0 || sitem2.path.indexOf(this.$route.path) >= 0)) {
													this.nowsMenu = sitem.id
													this.nowbMenu = item.id
													this.nowsMenu2 = sitem2.id;
													this.Smenus = item.children;
													haspage = true

												} else {


												}
											}

										}
									}
								}
							}

							if (!haspage) {
								console.log("未找到页面")
								let big = this.Menus[0]
								if (!big || big.children.length == 0) {
									this.nowsMenu = 0
									this.nowbMenu = big.id
									this.$router.push(big.path)
								} else {
									if (big.children.length > 0) {
										let second = big.children[0]
										if (!second || second.children.length == 0) {
											this.nowsMenu = second.id
											this.nowbMenu = big.id
											this.$router.push(second.path)
										} else {
											if (second.children.length > 0) {
												let third = second.children[0]
												this.nowsMenu = second.id
												this.nowbMenu = big.id
												this.nowsMenu2 = third.id;
												this.Smenus = big.children;
												this.$router.push(third.path)
											}
										}
									}
								}
							}
						}	
						resolve()



					});

				})
			})
		},
		async goBMenu(item) {
			console.log(346, item)
			this.Smenus = item.children;
			this.nowbMenu = item.id;
			if (this.Smenus && this.Smenus.length > 0) {

				//this.goPage(this.Smenus[0]);


				if (this.Smenus[0].children && this.Smenus[0].children.length > 0) {
					this.nowsMenu = this.Smenus[0].id;
					this.nowsMenu2 = this.Smenus[0].children[0].id;
					this.$router.push(this.Smenus[0].children[0].path);
				} else {
					this.$router.push(this.Smenus[0].path);
				}

			} else if (item.path) {

				this.$store.dispatch("setShowIframe", false)

				console.log(window.location.href)
				if (item.path.indexOf('http') < 0) {
					if (this.$route.path != item.path) {
						this.$router.push(item.path)
						this.nowsMenu2 = item.id
					}
				} else {
					console.log(402, item.path)
					if (window.location.href != item.path) {
						this.nowsMenu2 = item.id
						let path = item.path
						if (window.pmid == process.env.VUE_APP_PMID) {
							path = item.path.replace(/^https?:\/\/[^/]+/, "")
						}
						let spArr = item.path.split('/#/')
						let auth = await this.$getToken()
						path = `${spArr[0]}?showIframe=1&auth=${auth}&pmid=${window.pmid}/#${spArr[1]}`
						this.$router.push({
							path: "/iframe",
							query: {
								iframeUrl: path,
								t: Date.now(),
								nowbMenu: this.nowbMenu,
								nowsMenu2: item.id
							}
						})

						this.$store.dispatch("setShowIframe", true)
						this.$store.dispatch("setIframeUrl", path)
					}
				}

			}
		},

		async goPage(item) {
			this.$store.dispatch("setShowIframe", false)
			if (item.path.indexOf('http') < 0) {
				if (this.$route.path != item.path) {
					this.$router.push(item.path)
					this.nowsMenu2 = item.id
				}
			} else {
				if (window.location.href != item.path) {
					this.nowsMenu2 = item.id
					let path = item.path
					if (window.pmid == process.env.VUE_APP_PMID) {
						path = item.path.replace(/^https?:\/\/[^/]+/, "")
					}
					let spArr = item.path.split('/#/')
					let auth = await this.$getToken()
					path = `${spArr[0]}/#${spArr[1]}?showIframe=1&auth=${auth}&pmid=${window.pmid}`
					this.$router.push({
						path: "/iframe",
						query: {
							iframeUrl: path,
							t: Date.now(),
							nowbMenu: this.nowbMenu,
							nowsMenu2: item.id
						}
					})
					// window.location.href = `${window.location.origin}/#/iframe?iframeUrl=${path}`
					console.log(527, window.location.href)
					this.$store.dispatch("setShowIframe", true)
					this.$store.dispatch("setIframeUrl", path)
					setTimeout(function () {
						console.log(600, window.location.href)
						window.location.reload()
					},200)
				}
			}

		},

		handleChange() {

			for (let item of this.Smenus) {

				if (item.id == this.nowsMenu) {

					console.log(item)
					if (!item.children || item.children.length == 0) {
						this.nowsMenu = []
						this.nowsMenu2 = 0
						if (this.$route.path != item.path) {
							this.$router.push(item.path);
						}
						this.$nextTick(() => {
							this.nowsMenu = []
						})
					}
				}
			}
		},
		setYear() {

			sessionStorage.setItem("year", this.year);
			this.$http.post("/api/change_year", { year: this.year }).then(res => {
				window.location.reload();
			})

		},
		handleCommand(e) {
			console.log(e);
			if (e == "updPwd") {
				this.isShowModal = true;
			} else if (e == "logout") {
				this.$http.post("/api/logout", { authstr: localStorage.getItem("auth") }).then(res => {
					localStorage.clear();
					sessionStorage.clear();

					// this.$router.push("/login");
					window.location.reload()
				})

			} else if (e == "changeYear") {
				this.isShowYearModal = true;
			}
		},
		savePwd() {
			if (!this.pwdForm.oldPwd) {
				this.$message.error("请输入原密码");
				return;
			}

			if (!this.pwdForm.newPwd1) {
				this.$message.error("请输入新密码");
				return;
			}
			if (!this.pwdForm.newPwd2) {
				this.$message.error("请再次输入新密码");
				return;
			}
			if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
				this.$message.error("两次密码不一致");
				return;
			}
			this.$http.post("/api/reset_myself_pwd", this.pwdForm).then((ok) => {
				if (ok.data.code) {
					if (ok.data.code == 500) {
						this.$message.error(ok.data.msg);
					} else {
						this.$message.error("保存失败");
					}
				} else {
					this.$message.success("修改成功");

					this.isShowModal = false;
					this.$router.push("/login");
				}
			});
		},
		getTeacherInfo(callback) {

			return new Promise(resolve => {

				this.$http.post("/api/get_teacher_info").then(res => {
					this.avatar = res.data.avatar ? res.data.avatar : './img/temp.png'
					this.userName = res.data.username

					if (callback) {
						callback()
					}
					resolve()
				})
			})

		},

	},
};
</script>
<style lang="less" type="text/less">
.menuWrapper {
	.el-tabs {
		height: 100%;

		.el-tabs__header {
			height: 100%;
			margin: 0;

			.el-tabs__nav-wrap {
				height: 100%;
				margin: 0;

				.el-tabs__nav-scroll {
					height: 100%;
					margin: 0;

					.el-tabs__active-bar {
						/*active   tabitem*/
						height: 0;
					}

					.el-tabs__nav {
						height: 100%;
						margin: 0;

						.el-tabs__item {
							height: 100%;

						}
					}
				}

				.el-tabs__nav-next,
				.el-tabs__nav-prev {
					margin-top: 5px;
					height: 40px;
					line-height: 40px;
					color: #ffcb29;
					background-color: #ffffff51;
				}
			}

			.el-tabs__nav-scroll::after {
				/*el-tab 下方长横线*/
				height: 0 !important;
			}

			.el-tabs__nav-wrap::after {
				/*el-tab 下方长横线*/
				height: 0 !important;
			}
		}
	}

	.el-collapse-item__content {
		padding-bottom: 0 !important;
	}
}

.popper-class-cus {
	border-radius: 10px !important;
	box-shadow: 0 2px 12px 0 rgba(24, 144, 255, 0.5) !important;
}
</style>

<style scoped lang="less" type="text/less">
.menuWrapper {

	.el-menu {

		.is-active,
		.is-active i {
			font-size: 18px;
			font-weight: 700;
		}
	}

	.header {
		height: 50px;
		width: 100%;
		position: absolute;
		top: 0;
		z-index: 1;
		background-color: #2469f3;

		.leftlogo {
			width: 210px;
			/*background-color: #fff;*/

			.thumbnail {
				width: calc(100% - 20px);
				height: 50px;
				overflow: hidden;

				img {
					max-width: 100%;

				}
			}
		}

		.hidelogo {
			display: none;
		}

		.moudelname {
			/*margin-left: 20px;*/
			border-left: 4px solid #ffc501;
			color: #ffc501;
			padding-left: 12px;
			font-size: 18px;
			font-weight: bolder;
			white-space: nowrap;
		}

		.menubar {
			margin-left: 30px;
			height: 100%;
			overflow-y: hidden;

			.el-menu-item {
				border-bottom: 4px solid transparent !important;

			}

			.is-active {
				border-bottom: 4px solid #ffc501 !important;
			}

			ul {
				white-space: nowrap;
				height: 100%;

				li {
					height: 100%;
					line-height: 50px;
				}
			}

			img {
				width: 100%;
				margin-top: 0px;
			}

			.bmitem {
				height: 100%;
				/*padding: 3px 20px 0px 20px;*/
				border-radius: 4px;
				cursor: pointer;
				display: inline-block;
				vertical-align: top;
				font-weight: bolder;
				margin-right: 10px;
				font-size: 15px;
				color: #fff;

			}

			.bmckd {


				background-size: 100% 100%;
				font-size: 18px;
				color: #ffc501;
				transform: translateY(-2px);
				font-weight: bolder;

				.ckdline {
					height: 4px;
					background-color: #ffcb29;
					transform: translateY(-8px);
					border-radius: 2.5px;
				}
			}

		}

		.headright {
			margin: 0 20px 0 30px;
			height: 100%;
			cursor: pointer;

			.miniQRBox {
				margin-right: 20px;
				background-color: #fff;
				width: 36px;
				height: 36px;
				border-radius: 4px;
				border: 1px solid #0794b3;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.nickname {
				margin-left: 10px;
				color: #fff;
				border-radius: 4px;
				cursor: pointer;
				border: 0;
				outline: none;
			}

			.avatarImg {
				width: 30px;
				height: 30px;
				border: 1px solid #0794b3;
				border-radius: 50%;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					cursor: pointer;

				}
			}
		}

	}

	.menuline {
		height: 2px;
		border: 2px solid #b9cbdf;
		position: absolute;
		top: 50px;
		width: 100%;
		background-color: #e1e9f9;
	}

}




.leftmenu {
	width: 200px;
	height: 100%;
	position: absolute;

	transition: all .3s;
	transform-origin: left;


	.menuitem {
		padding: 10px;
		text-align: left;

		cursor: pointer;
		margin: 0 auto;

		font-size: 14px;
		color: #979797;
		font-weight: bolder;
		position: relative;

		.ckdtail {
			width: 6px;
			height: 12px;
			position: absolute;
			top: 16px;
			right: 0px;
			background-image: url(/img/mleft.png);
			background-size: auto 98%;

			background-repeat: no-repeat;



		}
	}

	// .menuitem:first-child {
	//   border-top-left-radius: 4px;
	//   border-top-right-radius: 4px;
	// }

	// .menuitem:last-child {
	//   border-bottom: 1px solid #0794b3;
	//   border-bottom-left-radius: 4px;
	//   border-bottom-right-radius: 4px;
	//   border-radius: 4px;
	// }

	.menuckd {
		color: #1890ff;
		// box-shadow: 0 0 6px 2px rgba(24, 144, 255, 0.2) inset;
		border-radius: 4px;
		// border-bottom-left-radius: 20px;
		// transform: translateX(10px);
		position: relative;
		background-color: #e5f0fa;
	}

	.bmenuckd {
		color: #478af3;
		font-weight: bolder;
	}
}




.menuckd .ckdtail {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 0px;
	right: 0px;
	background-image: url(/img/mleft.png);
	background-size: auto 98%;
	background-repeat: no-repeat;
}
</style>
